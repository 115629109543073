var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Admin: Page Feedback" }
      }),
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Feedback")]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "two-thirds" } },
            [
              _c(
                "ck-table-filters",
                { attrs: { "hide-extra": "" }, on: { search: _vm.onSearch } },
                [
                  _c(
                    "gov-form-group",
                    [
                      _c("gov-label", { attrs: { for: "filter[url]" } }, [
                        _vm._v("Page URL")
                      ]),
                      _c("gov-input", {
                        attrs: {
                          id: "filter[url]",
                          name: "filter[url]",
                          type: "search"
                        },
                        model: {
                          value: _vm.filters.url,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "url", $$v)
                          },
                          expression: "filters.url"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ck-resource-listing-table", {
        ref: "pageFeedbacksTable",
        attrs: {
          uri: "/page-feedbacks",
          params: _vm.params,
          "default-sort": "-created_at",
          columns: [
            {
              heading: "Page URL",
              sort: "url",
              render: function(pageFeedback) {
                return pageFeedback.url
              }
            },
            {
              heading: "Contact name",
              render: function(pageFeedback) {
                return pageFeedback.name || "-"
              }
            },
            {
              heading: "Contact details",
              render: function(pageFeedback) {
                return pageFeedback.email || pageFeedback.phone || "-"
              }
            },
            {
              heading: "Date / Time",
              sort: "created_at",
              render: function(pageFeedback) {
                return _vm.formatDateTime(pageFeedback.created_at)
              }
            }
          ],
          "view-route": function(pageFeedback) {
            return {
              name: "page-feedbacks-show",
              params: { pageFeedback: pageFeedback.id }
            }
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }